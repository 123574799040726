<template>
  <div class="detail-page-wrap">
    <div class="detail-page">
      <div class="detail-page__image">
        <NeoCarrusel :value="images" />
      </div>
      <div class="detail-page__name">
        <h1 class="heading-size-1">{{ artwork.title }}</h1>
        <h2 class="heading-size-2">{{ artwork.artist }}</h2>
      </div>
      <!-- <div class="detail-page__prices text-right">
        <WorkPrices :id="id" :info="artworkMetrics" />
      </div> -->
      <div class="detail-page__desc">
        <WorkDescription :id="id" :data="description" />
      </div>
      <div class="detail-page__provenance">
        <WorkArtistComments
          v-if="artwork.provenance"
          :id="id"
          :data="artwork.provenance"
        />
      </div>
      <div class="detail-page__social">
        <SocialMediaShare
          :id="id"
          :data="artwork.sns_links"
          :title="artwork.title"
          :artist="artwork.artist"
          :img="images[0]"
        />
      </div>
      <!-- <div class="detail-page__collectors">
        <Collectors :collectors="collectors" />
      </div> -->
    </div>
    <ArtistInfoWrap :artistInfo="artistInfo" :artworkId="id" />
  </div>
</template>

<script>
// import WorkPrices from "@/components/Organisms/WorkPrices";
import NeoCarrusel from "@/components/Molecules/NeoCarrusel";
import WorkDescription from "@/components/Molecules/WorkDescription";
import WorkArtistComments from "@/components/Molecules/WorkArtistComments";
import SocialMediaShare from "@/components/Molecules/SocialMediaShare";
// import Collectors from "@/components/Organisms/Collectors";
import ArtistInfoWrap from "@/components/Organisms/ArtistInfoWrap";

import EmergingArtworkDS from "@/services/EmergingArtworkDS";
import EmergingArtistDS from "@/services/EmergingArtistDS";
// import UsersDS from "@/services/UsersDS";

// import { analytics } from "@/helpers/firebase";

export default {
  name: "vEmergingWorkDetails",
  components: {
    // WorkPrices,
    NeoCarrusel,
    // Collectors,
    WorkDescription,
    WorkArtistComments,
    SocialMediaShare,
    ArtistInfoWrap,
  },
  props: {
    id: String,
    pTitle: String,
    pArtist: String,
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "ogurl",
          property: "og:url",
          content: `https://neoone.art${this.$route.fullPath}`,
        },
        { vmid: "ogtype", property: "og:type", content: "website" },
        {
          vmid: "ogtitle",
          property: "og:title",
          content: `${this.artwork.title} by ${this.artwork.artist} in NEO ONE ART`,
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_1.jpg`,
        },
        {
          vmid: "ogdescription",
          property: "og:description",
          content: "You can buy shares of this work of art at NEO ONE ART",
        },
        {
          vmid: "description",
          name: "description",
          content: "You can buy shares of this work of art at NEO ONE ART",
        },
        {
          vmid: "ogimagesecure_url",
          property: "og:image:secure_url",
          content: `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_1.jpg`,
        },
        {
          vmid: "ogimagetype",
          property: "og:image:type",
          content: "image/jpeg",
        },

        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: `${this.artwork.title} by ${this.artwork.artist} in NEO ONE ART`,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: "You can buy shares of this work of art at NEO ONE ART",
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_1.jpg`,
        },
        { vmid: "twitter:card", name: "twitter:card", content: "summary" },
        {
          vmid: "twitter:image:alt",
          name: "twitter:image:alt",
          content: `${this.artwork.title} by ${this.artwork.artist} in NEO ONE ART`,
        },
      ],
    };
  },
  data: () => ({
    artistId: "",
    artwork: {},
    artist: {},
    artistInfo: {
      id: "",
      artist: "",
      artistIntro: {
        en: '""',
        es: '""',
        ja: '""',
      },
      artistBio: {
        en: '""',
        es: '""',
        ja: '""',
      },
    },
    artworkMetrics: {},
    description: {
      year: "",
      material: "",
      height: "",
      width: "",
      short: {
        en: '""',
        es: '""',
        ja: '""',
      },
      long: {
        en: '""',
        es: '""',
        ja: '""',
      },
    },
    images: [],
    workData: {},
    collectors: [],
    artworkSubscriber: undefined,
    artworkRef: undefined,
  }),
  async beforeMount() {
    const info = await EmergingArtworkDS.read(this.id);

    this.artistId = info._artistID;
    this.artwork = info;
    this.description = {
      year: `${info.circa ? "Circa " : ""} ${info.year}`,
      material: info.material,
      height: info.height,
      width: info.width,
      short: info.short,
      long: info.long,
    };

    // try {
    //   analytics.logEvent("view_item", {
    //     id: this.id,
    //     title: this.artwork.title,
    //     artist: this.artwork.artist,
    //   });
    // } catch {
    //   console.log();
    // }

    // Put the main image in the first place in the array
    let tempImgInfo = [];
    for (let img in info.images) {
      if (info.images[img].main) {
        tempImgInfo.unshift(info.images[img]);
      } else {
        tempImgInfo.push(info.images[img]);
      }
    }
    this.images = tempImgInfo;

    const infoArtist = await EmergingArtistDS.read(info._artistID);

    this.artist = infoArtist;

    this.artistInfo = {
      id: info._artistID,
      artist: infoArtist.firstName + " " + infoArtist.lastName,
      artistIntro: infoArtist.shortPhrase,
      artistBio: infoArtist.bio,
      sns: JSON.parse(JSON.stringify(infoArtist.sns))
    };

  // this.artistInfo = {
  //     id: this.artistId,
  //     artist: "Sisti" + " " + "Franz",
  //     artistIntro: {
  //       en: '"Un nuevo artista chileno"',
  //       es: '"Un nuevo artista chileno"',
  //       ja: '"Un nuevo artista chileno"',
  //     },
  //     artistBio: infoArtist.bio,
  //     // artistBio: {
  //     //   en: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //     //   es: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //     //   ja: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //     // },
  //   };
  //   this.artistId = "1234567";
  //   this.artwork = {
  //     artist: "Sisti Franz",
  //     title: "Un obra emergente",
  //   };
  //   this.description = {
  //     year: 2020,
  //     material: {
  //       en: "oleo",
  //       es: "oleo",
  //       ja: "oleo",
  //     },
  //     height: 65,
  //     width: 45,
  //     short: {
  //       en: '"this is a short description"',
  //       es: '"this is a short description"',
  //       ja: '"this is a short description"',
  //     },
  //     long: {
  //       en: "This is a longer description. This is a longer description. This is a longer description. This is a longer description.",
  //       es: "This is a longer description. This is a longer description. This is a longer description. This is a longer description.",
  //       ja: "This is a longer description. This is a longer description. This is a longer description. This is a longer description.",
  //     },
  //   };

  //   this.images = [
  //     {
  //       main: true,
  //       url: "https://images.neoone.art/emerging/emerging_test.jpg",
  //     },
  //     { url: "https://images.neoone.art/emerging/emerging_test.jpg" },
  //   ];

  //   this.artistInfo = {
  //     id: this.artistId,
  //     artist: "Sisti" + " " + "Franz",
  //     artistIntro: {
  //       en: '"Un nuevo artista chileno"',
  //       es: '"Un nuevo artista chileno"',
  //       ja: '"Un nuevo artista chileno"',
  //     },
  //     artistBio: {
  //       en: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //       es: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //       ja: '"<p>Esta es la biografia del nuevo artista chileno. Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p><p>Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.Esta es la biografia del nuevo artista chileno.</p>"',
  //     },
  //   };
  },

  // this.artworkRef = ArtworkDS.refArtworkMetrics(this.id);
  // this.artworkSubscriber = this.artworkRef.on("value", (snapshot) => {
  //   this.artworkMetrics = snapshot.val();
  // });

  // const info = await ArtworkDS.read(this.id);

  // this.artistId = info._artistID;
  // this.artwork = info;
  // this.description = {
  //   year: `${info.circa ? "Circa " : ""} ${info.year}`,
  //   material: info.material,
  //   height: info.height,
  //   width: info.width,
  //   short: info.short,
  //   long: info.long,
  // };

  // try {
  //   analytics.logEvent("view_item", {
  //     id: this.id,
  //     title: this.artwork.title,
  //     artist: this.artwork.artist,
  //   });
  // } catch {
  //   console.log();
  // }

  // // Put the main image in the first place in the array
  // let tempImgInfo = [];
  // for (let img in info.images) {
  //   if (info.images[img].main) {
  //     tempImgInfo.unshift(info.images[img]);
  //   } else {
  //     tempImgInfo.push(info.images[img]);
  //   }
  // }
  // this.images = tempImgInfo;

  // const infoArtist = await ArtistDS.read(info._artistID);

  // this.artist = infoArtist;
  // this.artistInfo = {
  //   id: info._artistID,
  //   artist: infoArtist.firstName + " " + infoArtist.lastName,
  //   artistIntro: infoArtist.shortPhrase,
  //   artistBio: infoArtist.bio,
  // };

  // this.collectorList = [];
  // UsersDS.getCollectorsByWorkId(this.id).then((snap) => {
  //   if (snap.exists()) {
  //     const collectors = snap.val();
  //     for (let collector in collectors) {
  //       this.collectors.push({
  //         id: collector,
  //         username: collectors[collector].username,
  //         url: `${process.env.VUE_APP_IMAGES_URL}avatars/${collector}.jpg`,
  //         ownership: collectors[collector].quantity / info.totalFrames,
  //       });
  //     }
  //   }
  // });
};
</script>

<style lang="scss" scoped>
.detail-page-wrap {
  background-color: var(--color-white);
}
.detail-page {
  max-width: var(--main-width-container);
  --detail-page-padding: 16px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: var(--detail-page-padding) 0;
  grid-template-areas:
    "dp-name"
    "dp-image"
    "dp-desc"
    "dp-provenance"
    "dp-social";
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "dp-name       dp-name       dp-name"
      "dp-image      dp-image      dp-image"
      "dp-desc       dp-desc       dp-desc"
      "dp-provenance dp-provenance dp-provenance"
      "dp-social2 dp-social dp-social";
  }
  @media only screen and (min-width: 1025px) {
    grid-template-columns: 500px 1fr 1fr;
    grid-template-areas:
      "dp-image  dp-name  dp-name"
      "dp-image  dp-social dp-social"
      "dp-image  dp-desc dp-desc"
      "dp-image  dp-provenance dp-provenance";
  }
  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(2, 350px) repeat(2, 1fr);
    grid-template-areas:
      "dp-image dp-image dp-name dp-name"
      "dp-image dp-image dp-desc dp-desc"
      "dp-image dp-image dp-provenance dp-provenance "
      "dp-image dp-image dp-social dp-social";
      // "dp-image dp-image dp-social2 dp-social2";
  }

  &__image {
    grid-area: dp-image;
    @media only screen and (max-width: 767px) {
      min-height: 400px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      min-height: 450px;
    }
  }

  &__name {
    grid-area: dp-name;
  }

  &__prices {
    grid-area: dp-prices;
  }

  &__desc {
    grid-area: dp-desc;
  }

  &__provenance {
    grid-area: dp-provenance;
  }

  &__collectors {
    grid-area: dp-collectors;
  }

  &__social {
    grid-area: dp-social;
  }

}
</style>
