import { db, rtdb } from "@/helpers/firebase";

const COLLECTION = "EmergingArtists";
const collectionRef = db.collection(COLLECTION);
import localforage from "localforage";

class EmergingArtistDS {
  async read(_id) {
    const snapshot = await collectionRef.doc(_id).get();
    const data = snapshot.data();
    return data;
    // const dataPromise = new Promise((resolve, reject) => {
    //   localforage.getItem(_id).then(async (cachedData) => {
    //     const ts = await localforage.getItem(`${_id}TS`) || 0;
    //     // const age = (Math.floor((Date.now()/1000) - ts))/86400; // age in days
    //     const age = (Math.floor((Date.now()/1000) - ts))/60; // age in minutes
    //     if (cachedData && (age < 720 || navigator.Online)) resolve(cachedData);
    //     else {
    //       try {
    //         const snapshot = await collectionRef.doc(_id).get();
    //         const data = snapshot.data();
    //         localforage.setItem(_id, data);
    //         localforage.setItem(`${_id}TS`, Math.floor(Date.now() / 1000));
    //         resolve(data);
    //       } catch {
    //         reject();
    //       }
    //     }
    //   });
    // });
    // return dataPromise;
  }

  async getPublishedFS(){
    const snapshot = await collectionRef.get();
    // return snapshot.docs
    let emerging = [];
    snapshot.docs.forEach((artist) => {
      const a = artist.data();

      emerging.push({
        _id: artist.id,
        artist: a.nickname,
        birthCountry: a.birthCountry,
        year: (new Date(a.birthdate)).getFullYear(),
        works: a.artwork,
        url: a.images[0].url,
      })
    })

    return emerging
    
    // const data = snapshot.docs();
    
  }

  async getPublishedRT() {
    let dbArtwork;
    let firstRound = [];
    let featured = [];
    let cachedArtworkEmpty = { featured, firstRound };
    // if (!navigator.onLine) {
    //   try {
    //     let cachedArtwork = await localforage.getItem("emerging");
    //     // IndexedDB did not find the data, try localStorage
    //     if (!cachedArtwork) {
    //       cachedArtwork = localStorage.getItem("emerging");
    //       if (cachedArtwork === null) return cachedArtworkEmpty;
    //       else return JSON.parse(cachedArtwork);
    //     } else {
    //       return cachedArtwork;
    //     }
    //   } catch (e) {
    //     return cachedArtworkEmpty;
    //   }
    // }
    try {
      dbArtwork = await rtdb.ref("emerging/artists").child("published").get();
    } catch {
      try {
        let cachedArtwork = await localforage.getItem("emerging");
        // IndexedDB did not find the data, try localStorage
        if (!cachedArtwork) return cachedArtworkEmpty;
        else return cachedArtwork;
      } catch (e) {
        return cachedArtworkEmpty;
      }
    }

    const artwork = dbArtwork.val();
    for (let artworkId in artwork) {
      const info = artwork[artworkId].info;
      const metrics = artwork[artworkId].metrics;
      const data = {
        _id: artworkId,
        title: info.artwork,
        artist: info.artist,
        stock: info.availableFrames,
        year: info.year,
        currency: metrics.currency,
        currentValue: metrics.currentValue,
        originalValue: metrics.originalValue,
        currentFrameValue: metrics.currentFrameValue,
        originalFrameValue: 1000,
        new: info.new,
        src: `${process.env.VUE_APP_IMAGES_URL}artwork/${artworkId}/${artworkId}_1.jpg?1`,
        flex: 3,
      };
      if (info.featured) {
        featured.push(data);
      }
      firstRound.push(data);
    }
    localforage.setItem("artwork", { featured, firstRound }, function (err) {
      // if err is non-null, we got an error
      if (err) console.log("failed to save cache ", err);
    });

    return { featured, firstRound };
  }

}

export default new EmergingArtistDS();
